<!--
 * @Descripttion:
 * @version:
 * @Author: 张圳
 * @Date: 2023-11-19 10:09:39
 * @LastEditors: Andy
 * @LastEditTime: 2023-11-19 10:17:56
-->
<template>
  <div class="info-box">
    <com-title>学习计划介绍内容</com-title>
    <div class="info-text">
      学习计划介绍内容学习计划介绍内容学习计划介绍内容学习计划介绍内容学习计划介绍内容学习计划介绍内容学习计划介绍内容学习计划介绍内容学习计划介绍内容学习计划介绍内容学习计划介绍内容学习计划介绍内容学习计划介绍内容学习计划介绍内容学习计划介绍内容学习计划介绍内容容学习计划介绍内容学习计划介绍内容学习计划介绍内容学习计划介
    </div>
  </div>
</template>

<script>
import comTitle from '@/components/comTitle/index.vue'
export default {
  components: {
    comTitle
  }
}
</script>

<style lang="scss" scoped>
.info-box {
  min-height: 650px;
  .info-text {
    margin-top: 8px;
    font-size: 14px;
    color: #4d4d4d;
  }
}
</style>
