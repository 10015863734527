<!--
 * @Descripttion:
 * @version:
 * @Author: 张圳
 * @Date: 2023-11-19 13:36:07
 * @LastEditors: Andy
 * @LastEditTime: 2023-11-19 13:44:44
-->
<template>
  <div class="container-warp">
    <div class="study-item">
      <div class="study-item-left">
        <div class="img-box">
          <img src="@/assets/img/img.jpg" alt="" />
        </div>
        <div class="info-box">
          <div class="info-box-t">
            试卷名称
            <span>阶段名称</span>
          </div>
          <div class="info-box-m">考试时间 2023-10-10</div>
          <div class="info-box-b">
            考试成绩<span style="color: #0089ff">--</span>分
          </div>
        </div>
      </div>
      <div class="study-item-right">
        <el-button type="text">考试详情</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.study-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;
  margin-bottom: 16px;
  background: linear-gradient(to bottom, #f0f8ff, #ffffff);
  border: 1px solid #e7f4ff;
  border-radius: 8px;
  .study-item-left {
    display: flex;
    .img-box {
      margin-right: 12px;
      width: 120px;
      height: 90px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 4px;
      }
    }
    .info-box {
      .info-box-t {
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        span {
          display: inline-block;
          padding: 3px 12px;
          margin-left: 16px;
          font-size: 12px;
          font-weight: 400;
          color: #ffffff;
          border-radius: 8px 0px 8px 0px;
          background: linear-gradient(to right, #31adf6, #5096ff);
        }
      }
      .info-box-m {
        margin: 8px 0 16px;
        font-size: 14px;
        color: #666666;
      }
      .info-box-b {
        font-size: 14px;
        color: #666666;
      }
    }
  }
}
</style>
