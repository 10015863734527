<!--
 * @Descripttion:
 * @version:
 * @Author: 张圳
 * @Date: 2023-11-19 10:28:24
 * @LastEditors: Andy
 * @LastEditTime: 2023-11-19 16:01:50
-->
<template>
  <div class="container-warp">
   <div>
    <com-title style="margin-bottom:16px;">第一阶段</com-title>
    <div class="study-item">
          <div class="study-item-left">
            <div class="img-box">
              <img src="@/assets/img/img.jpg" alt="" />
            </div>
            <div class="info-box">
              <div class="info-box-t">
                线上课程名称
                <span>线上课程</span>
              </div>
              <div class="info-box-m">
                计划描述计划描述计划描述计划描述计划描述计划描述计划描述
              </div>
              <div class="info-box-b">
                <div style="color:#409EFF;">已学24%</div>
                <el-progress :percentage="24" :show-text='false'></el-progress>
              </div>
            </div>
          </div>
          <div class="study-item-right">
            <el-button  type="text" @click="_continueStudy">继续学习</el-button>
            <el-button  type="text" @click="_startStudy">开始学习</el-button>
            <el-button  type="text" >考试结果</el-button>
            <el-button  type="text" >考试详情</el-button>
          </div>
        </div>
   </div>
  </div>
</template>

<script>
import comTitle from '@/components/comTitle/index.vue'
export default {
  components: {
    comTitle
  },
  methods: {
    // 开始学习
    _startStudy () {
      this.$router.push({
        path: '/myOnlineCourseDetails'
      })
    },
    // 继续学习
    _continueStudy () {
      this.$router.push({
        path: '/myOfflineCourseDetails'
      })
    }
  }
}
</script>

<style lang='scss' scoped>
.container-warp{
  min-height: 650px;
}
  .study-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;
  margin-bottom: 16px;
  background: linear-gradient(to bottom, #f0f8ff, #ffffff);
  border: 1px solid #e7f4ff;
  border-radius: 8px;
  .study-item-left {
    display: flex;
    .img-box {
      margin-right: 12px;
      width: 120px;
      height: 90px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 4px;
      }
    }
    .info-box {
      .info-box-t {
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        span {
          display: inline-block;
          padding: 3px 12px;
          margin-left: 16px;
          font-size: 12px;
          font-weight: 400;
          color: #ffffff;
          border-radius: 8px 0px 8px 0px;
          background: linear-gradient(to right, #31adf6, #5096ff);
        }
      }
      .info-box-m {
        margin: 8px 0 16px;
        font-size: 14px;
        color: #666666;
      }
      .info-box-b {
        display: flex;
        align-items: center;
        /deep/.el-progress{
          width: 265px;
        }
      }
    }
  }
}
</style>
