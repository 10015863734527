<template>
  <div class="container-warp">
    <div class="top-box">
      <back-title>学习计划详情</back-title>
      <div class="top-box-info">
        <div class="top-box-info-left">
          <img src="@/assets/img/login-bg.jpg" alt="" />
          <div class="top-box-info-left-xx">
            <div class="top-box-info-left-bt">
              【鉴定知识】贵金属鉴定学习<span>入职培训</span>
            </div>
            <div class="top-box-info-left-kc">
              计划描述计划描述计划描述计划描述计划描述计划描述计划描述
            </div>
            <div class="top-box-info-left-mc">
              <div class="info-box-b-l">
                <img src="@/assets/img/icon_Duration.png" alt="" />总时长
                <span style="color: #0089ff">200</span>min
              </div>
              <div class="line-box"></div>
              <div class="info-box-b-m">
                <img src="@/assets/img/icon_Course.png" alt="" /><span
                  style="color: #0089ff"
                  >3</span
                >门课程
              </div>
              <div class="line-box"></div>
              <div class="info-box-b-r">
                <img src="@/assets/img/icon_Examination.png" alt="" />考试<span style="color: #0089ff">0/1</span>(通过0场)
              </div>
              <div class="line-box"></div>
              <div class="info-box-b-r">
                <img src="@/assets/img/icon_Finaldate.png" alt="" />结束时间
                2023-01-10
              </div>
            </div>
          </div>
        </div>
        <div class="top-box-info-right">
          <el-button type="primary" plain>继续学习</el-button>
        </div>
      </div>
    </div>
    <div class="bottom-box">
      <el-tabs
        class="bottom-tab-box"
        v-model="activeName"
      >
        <el-tab-pane label="介绍" name="1">
          <introduce></introduce>
        </el-tab-pane>
        <el-tab-pane label="课程安排" name="2">
          <course-arrangement></course-arrangement>
        </el-tab-pane>
        <el-tab-pane label="考试" name="3">
          <exam></exam>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import backTitle from '@/components/backTitle/index.vue'
import introduce from '../components/introduce.vue'
import courseArrangement from '../components/courseArrangement.vue'
import exam from '../components/exam.vue'
export default {
  components: {
    backTitle,
    introduce,
    courseArrangement,
    exam
  },
  data () {
    return {
      activeName: '1'
    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.top-box {
  padding: 16px 24px 24px;
  background-color: #ffffff;
  border-radius: 4px;
  .top-box-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .top-box-info-left {
      display: flex;
      img {
        margin-right: 16px;
        width: 120px;
        height: 90px;
        border-radius: 4px;
      }
      .top-box-info-left-xx {
        .top-box-info-left-bt {
          margin: 4px 0;
          font-size: 16px;
          color: #333333;
          font-weight: 500;
          span {
            display: inline-block;
            padding: 3px 12px;
            margin-left: 16px;
            font-size: 12px;
            color: #ffffff;
            border-radius: 8px 0px 8px 0px;
            background: linear-gradient(to right, #31adf6, #5096ff);
          }
        }
        .top-box-info-left-kc {
          margin-bottom: 14px;
          font-size: 14px;
          color: #666666;
        }
        .top-box-info-left-mc {
          margin-left: 6px;
          display: flex;
          align-items: center;
          font-size: 12px;
          color: #666666;
          .info-box-b-l,
          .info-box-b-m,
          .info-box-b-r {
            img {
              margin-right: 4px;
              width: 20px;
              height: 20px;
              vertical-align: middle;
            }
          }
          .line-box {
            margin: 0 12px;
            width: 1px;
            height: 16px;
            background: #dcdcdc;
          }
        }
      }
    }
  }
}
/deep/.el-button {
  margin-left: 16px !important;
  font-size: 14px;
  height: 32px;
}
.bottom-box {
  margin-top: 16px;
  padding: 0 24px;
  background-color: #ffffff;
  border-radius: 4px;
  .bottom-tab-box {
    /deep/.el-tabs__nav {
      height: 55px;
    }
    /deep/.el-tabs__item {
      line-height: 55px;
    }
  }
}
</style>
